<template>
  <b-modal
    id="imprintModal"
    title="Imprint Information according to §5 TMG"
    size="lg"
    hide-footer
    centered
    scrollable
  >
    <div class="mb-4">
      <p class="font-weight-bold">QMware GmbH</p>
      <p class="mb-0">Barth Strasse 18</p>
      <p class="mb-0">D-80339 Munich</p>
      <p class="mb-3">E-Mail: meet@qm-ware.com</p>
      <p>VAT identification number: DE344668681 according to §27a VAT law</p>
    </div>
    <div class="mb-4">
      <p class="mb-0">Register Court Munich</p>
      <p>HRB: 266640</p>
    </div>
    <div class="mb-7">
      <p class="mb-0">
        Represented by the managing director (Geschäftsführer):
      </p>
      <p>Markus Pflitsch</p>
    </div>
    <div class="mb-4">
      <p class="mb-2">
        Responsible for editorial content / journalistically edited Services
        according to Section 18 (2) of the German Interstate Media Treaty:
      </p>
      <p class="mb-0">Mira Dechant</p>
      <p class="mb-0">QMware GmbH</p>
      <p class="mb-0">Barthstrasse 18</p>
      <p class="mb-0">D-80339 Munich</p>
    </div>
    <div class="mb-2">
      <p class="font-weight-bold">Links</p>
      <p>
        Basically we are pleased about link references to the QMware pages and
        ask for a pre-information, if you insert a link to our page at your
        site.
      </p>
    </div>
    <div class="mb-2">
      <p class="font-weight-bold">
        Source information for the used pictures and graphics
      </p>
      <p>pixabay.com</p>
    </div>
    <div class="mb-2">
      <p class="font-weight-bold">Disclaimer</p>
      <p>
        QMware GmbH is not responsible for and has no influence on the content
        of pages of other companies. Links to external providers do not
        represent any valuation, assessment or recommendation by QMware GmbH.
        The information on the QMware GmbH website is continuously checked with
        care. However, QMware GmbH cannot guarantee for completeness or
        correctness.
      </p>
    </div>
    <div class="mb-4">
      <p class="font-weight-bold">Registered trademarks, names, brands</p>
      <p>
        The use of registered trademarks, names or brands without labeling are
        made in agreement with their owners and were contractually approved by
        the owners in advance.
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Imprint",
  data() {
    return {};
  },
  methods: {},
};
</script>
