<template>
  <b-modal
    id="dataProtectionModal"
    title="Data Protection Policy"
    size="lg"
    hide-footer
    centered
    scrollable
  >
    <div class="mb-4">
      <h5 class="font-weight-bold">
        1. General information and principles of data processing
      </h5>
      <p class="mb-0">
        We are pleased that you are visiting our website. The protection of your
        privacy and the protection of your personal data, the so-called personal
        data, is an important concern to us.
      </p>
      <p class="mb-0">
        This data protection policy applies to the General Data Protection
        Regulation (GDPR) and the Swiss Federal Act on Data Protection (FADP).
      </p>
      <p class="mb-0">
        In accordance with Article 4(1) GDPR, personal data means any
        information relating to an identified or identifiable natural person.
        This includes, for example, information such as first and last name,
        address, telephone number, email address, but also an IP address. Data
        that cannot be linked to your person, for example through anonymisation,
        is not personal data. Processing of personal data (e.g. collection,
        storage, readout, retrieval, use, transmission, deletion or destruction)
        according to Article 4(2) GDPR always requires a legal basis or a
        consent. Processed personal data must be deleted as soon as the purpose
        of their processing has been achieved, and there are no longer any
        legally prescribed retention obligations.
      </p>
      <p class="mb-0">
        In accordance with Article 5(a) FADP personal data means any information
        relating to an identified or identifiable natural person.
      </p>
      <p class="mb-0">
        Here you will find information on the handling of your personal data
        upon visiting our website. In order to provide the functions and
        services of our website, it is necessary for us to collect your personal
        data.
      </p>
      <p class="mb-0">
        In the following, we explain the type and scope, purpose, legal basis
        and storage period of the respective data processing. This data
        protection policy only applies to this particular website. It does not
        apply for other websites which are merely referenced via hyperlink. We
        cannot assume responsibility for the confidential handling of your
        personal data on these third-party websites, since we do not have any
        influence in the data protection compliance by these companies. Please
        inform yourself on the handling of personal data by these companies
        directly on their websites.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold">2. Controller</h5>
      <p class="mb-0">
        Responsible for the processing of personal data on this website (see
        imprint) is:
      </p>
      <p class="mb-0">QMware GmbH</p>
      <p class="mb-0">Barthstraße 18</p>
      <p class="mb-0">80339 Munich</p>
      <p class="mb-0">Email: info@qm-ware.com</p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold">3. Data Protection Officer</h5>
      <p class="mb-0">
        If you have any further questions regarding data protection, please feel
        free to contact our data protection officer:
      </p>
      <p class="mb-0">Data Protection Officer</p>
      <p class="mb-0">QMware GmbH</p>
      <p class="mb-0">Barthstraße 18</p>
      <p class="mb-0">80339 Munich</p>
      <p class="mb-0">Email: GDPR@qm-ware.com</p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold">
        4. Provision and use of the website/ server log files
      </h5>
      <p class="mb-0 font-weight-bold">a) Type and extent of data processing</p>
      <p class="mb-0">
        With each access of a user to an internet page of our web offer and each
        retrieval of a file, data about this procedure will automatically be
        recorded in a log file and will then be processed. If you use this
        website without otherwise (e.g. through registration or when using the
        contact form) transmitting data to us, we will collect technically
        necessary data over server log files, which will automatically be
        transmitted to our server: • information about the browser type and
        version used • operating system of the user • IP-address of the user •
        date and time of the request • accessed page/ name of the retrieved file
        • transferred data volume • notification, whether the access/ retrieval
        was successful • internet address, from which the site respectively the
        file or the desired function was initiated (referrer URL)
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        The legitimate interest for the collection and processing of the
        mentioned data including the IP-address is based on the fact, that this
        data is necessary in order to provide the use of our web offer, for
        instance to display the accessed website. In addition, the legitimate
        interest in the storage of the IP-address is based on the requirement to
        guarantee IT security, in particular the protection of our IT systems
        against misuse and to ward off attacks.
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        Legal basis for the collection and processing of the data is Article
        6(1)(f) GDPR respectively Section 25(2) TTDSG.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        The aforementioned data will be recorded for the duration of the
        communication process. To guarantee IT security, the IP-address will be
        saved for an additional short period of time of no more than seven
        calendar days. Subsequently, this data shall be deleted.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of objection</p>
      <p class="mb-0">
        If your personal data is processed in accordance with Article 6(1)(f)
        GDPR you have a right of objection in accordance with Article 21 GDPR.
        However, in the case of the specific data processing operation, we have
        compelling legitimate grounds for the processing the data that are
        necessary for the protection of these data, because without the
        processing of these data we cannot provide and operate our website.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold">5. Use of cookies</h5>
      <p class="mb-0">
        We use cookies. Cookies are small files that are placed on your computer
        and stored by your browser. Some functions of our website cannot be
        offered without the use of technically necessary cookies, whereas other
        cookies allow us to perform various analyses. For example, some cookies
        can recognize the browser you are using when returning to our website
        and transmit various information to us. We use cookies in order to
        facilitate and improve the use of our website. For instance, through
        cookies we can create a more user-friendly and effective web offer for
        you, for example by retracing your use of our website and determining
        your preferred settings (e.g. country and language settings).
      </p>
      <p class="mb-0">
        If third parties process information via cookies, this information will
        be directly collected via your browser. Cookies do not cause any damage
        to your terminal device. They can neither run programs nor contain
        viruses. Various types of cookies are used on our website, their type
        and function are explained in the following.
      </p>
      <p class="mb-0">
        If cookies or cookie-like technologies are used in the context of data
        processing on this website, the use of electronic communications
        networks to store information or to gain access to information stored in
        the terminal equipment of user is based on your consent pursuant to
        Section 25(1) Telecommunications Telemedia Data Protection Act (TTDSG)
        in conjunction with the requirements of consent under data protection
        law pursuant to Article 4(11), 7 GDPR.
      </p>
      <p class="mb-0">
        If the sole purpose of storing information or gaining access or
        information stored in the terminal equipment of users is a technical
        storage or access of carrying out or facilitating the transmission of a
        communication over an electronic communications network or is strictly
        necessary in order to provide an information society service explicitly
        requested by the user the data processing on this website is carried out
        on the basis of cookies or cookie-like technologies on the basis of
        Section 25 (2) TTDSG and a consent is not required.
      </p>
      <p class="mb-3">
        For the following processing of personal data, the general requirements
        of the GDPR pursuant to Article 6(1) GDPR must be observed:
      </p>
      <p class="mb-0" style="font-size: 12px">
        – if you have given your consent, the legal basis for the subsequent
        processing of personal data is Article 6(1)(a) GDPR.
      </p>
      <p class="mb-3" style="font-size: 12px">
        – if the processing of personal data is necessary due to our legitimate
        interest, the legal basis for the subsequent processing of personal data
        is Article 6(1)(f) GDPR.
      </p>
      <p class="font-weight-bold mb-0">a) Temporary cookies/ session cookies</p>
      <p class="mb-0">
        Our website uses so-called temporary cookies or session cookies, which
        are automatically deleted when you close your browser. Through this type
        of cookies, it is possible to record your session ID. This allows
        various requests from your browser to be assigned to a common session
        and makes it possible to recognize your terminal device during
        subsequent visits to the website. These session cookies expire at the
        end of the session.
      </p>
      <p class="font-weight-bold mb-0">b) Persistent cookies</p>
      <p class="mb-0">
        Our website uses so-called persistent cookies. Persistent cookies are
        cookies that are stored in your browser over a longer period of time and
        can transmit information. The respective storage period varies depending
        on the cookie. Permanent cookies may be deleted independently via your
        browser settings.
      </p>
      <p class="font-weight-bold mb-0">c) Configuration of browser settings</p>
      <p class="mb-0">
        Most web browsers are pre-set to accept cookies automatically. However,
        you can configure your browser to only accept only certain or reject all
        cookies. Having said this, we would like to point out that you may then
        no longer be able to use all of our website’s functions. Additionally,
        you can use your browser settings to delete cookies already stored in
        your browser. Furthermore, it is possible to set up your browser in such
        a way that you are informed before cookies are stored. Since the
        different browsers may vary in their respective functions, we ask you to
        use the help menu of your browser for the corresponding configuration
        options. Disabling the use of cookies may require the storage of a
        permanent cookie on your computer. If you subsequently delete this
        cookie, you will have to set it again for it to remain effective.
      </p>
      <p class="font-weight-bold">
        d) Cookie Consent with the consent management platform Borlabs Cookie
      </p>
      <p class="mb-0">
        Our website uses consent management platform Borlabs Cookie to obtain
        your consent in the storage of cookies in your browser and document
        these in compliance with data protection. Provider of Borlabs Cookie is
        Borlabs – Benjamin A. Bornschein, Rübenkamp 32, 22305 Hamburg. Upon
        entering the website, Borlabs Cookie stores a cookie in your browser, in
        which your obtained consent or the revocation of consent are documented.
        However, this data will not be transmitted to the provider Borlabs
        Cookie. This is a required cookie, which does not need a consent. Legal
        basis for the data processing is Article 6(1)(a) GDPR. We use Borlabs
        Cookie to ensure compliance with our legal obligations.
      </p>
      <p class="mb-0">
        The cookies are stored until you ask us to delete this data, you delete
        the cookie yourself or the storage is no longer necessary for the
        purpose of data processing. Further information on Borlabs Cookie can be
        found here:
      </p>
      <p class="mb-0">
        https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/.
      </p>
      <p class="mb-0">
        You can change your cookie settings at any time using the following
        link:
      </p>
      <b-link style="font-size: 12px" href="#">Cookie Preference</b-link>
      <p class="font-weight-bold mt-1 mb-0">e) Categories of cookies</p>
      <p class="mb-0">We use the following categories of cookies:</p>
      <p class="mb-0 font-weight-bold">Required cookies</p>
      <p class="mb-0">
        Required cookies ensure functions that are essential to use our website
        as intended. These absolutely necessary cookies are used, for example,
        to ensure that registered users remain logged in when accessing various
        subpages. These are so-called first party cookies are only used by us.
        The legal basis for the processing of your personal data is Article
        6(1)(f) GDPR, as we have a legitimate interest in maintaining the
        functionality of our website. You have a right of objection according to
        Article 21 GDPR. In the case of technically necessary cookies, however,
        we have compelling reasons worthy of protection for processing the data,
        because without processing this data we cannot properly provide our
        website or the respective functionality of the website. As soon as the
        cookies are no longer required for the purposes described, they are
        deleted.
      </p>
      <p class="mb-0 font-weight-bold">Statistics cookies</p>
      <p class="mb-2">
        Statistics Cookies collect information about how a website is used in
        order to improve its attractiveness, content and functionality. For
        example, the following data is collected:
      </p>
      <p class="mb-0" style="font-size: 12px">
        • number of visits to a website or sub-pages
      </p>
      <p class="mb-0" style="font-size: 12px">• time spent on the website</p>
      <p class="mb-0" style="font-size: 12px">• sequence of visited pages</p>
      <p class="mb-0" style="font-size: 12px">• search terms</p>
      <p class="mb-0" style="font-size: 12px">
        • country, region, city from which access is made
      </p>
      <p class="mb-2" style="font-size: 12px">
        • analysis which areas of our website are of particular interest to you
      </p>
      <p class="mb-0">
        The legal basis for the processing of this personal data is your consent
        in accordance with Article 6(1)(a) GDPR. As soon as the cookies are no
        longer required for the purposes described, the storage period ends or
        you withdraw your consent, these cookies are deleted.
      </p>
      <p class="font-weight-bold">Marketing cookies</p>
      <p class="mb-0">
        Marketing cookies are used to display interest-based advertisements to
        website visitors. Besides they are also used to limit the frequency of
        display and measure the effectiveness of advertisement campaigns. The
        information obtained with third parties such as advertisers. Cookies to
        improve targeting and advertising are often linked to third party site
        functionalities.
      </p>
      <p class="mb-0">
        The legal basis for the processing of this personal data is your consent
        pursuant to Section 25(1) TTDSG in conjunction with Article 4(11), 7
        GDPR respectively Article 6(1)(a) GDPR for the following processing of
        personal data. As soon as the cookies are no longer required for the
        purposes described, the storage period ends or you withdraw your
        consent, these cookies are deleted.
      </p>
      <p class="font-weight-bold">
        Cookies by external services/ other cookieless data transmissions to
        external services
      </p>
      <p class="mb-0">
        External content of video- or social media platforms are blocked by
        default. If you consent in using a cookie and / or the disclosure of
        your data to external services, we will display this external content
        and transmit your data to these external services
      </p>
      <p class="mb-0">
        The legal basis for the processing of this personal data is your consent
        in accordance with Article 6(1)(a) GDPR. As soon as the personal data
        are no longer required for the purposes described, the storage period
        ends or you withdraw your consent, these personal data are deleted. f)
        List of cookies we use A list and description of the cookies we use can
        be found under the Privacy Preferences
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold">6. Services with required cookies</h5>
      <p class="mb-0">
        We use required cookies for our consent management platform.
      </p>
      <p class="mb-0 font-weight-bold">Google Tag Manager</p>
      <p>
        We use Google Tag Manager. Google Tag Manager is a service of Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, Tel:
        +353 1 543 1000, Fax: +353 1 686 5660, (“Google”) that allows marketers
        to manage website tags through a single interface.
      </p>
      <p class="font-weight-bold">GDPR:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. The USA is a so-called third
        country, because it is located outside the EU. However, the USA has an
        adequacy decision from the European Commission (EU-U.S. Data Privacy
        Framework (DPF). The decision concludes that the United States ensures
        an adequate level of protection – comparable to that of the European
        Union – for personal data transferred from the EU to US companies under
        the new framework. Google LLC has certified itself according to the DPF:
      </p>
      <b-link
        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
        >https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active</b-link
      >
      <p class="font-weight-bold mt-4">FADP:</p>
      <p class="mb-0"></p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. We would like to point out that
        the European Court of Justice (ECJ) has doubts about the adequacy of the
        level of data protection in the USA. In particular, there is a risk that
        personal data may be processed by government authorities for control and
        monitoring purposes, possibly also without any legal remedy.
      </p>
      <p class="mb-0">
        Google Tag Manager only implements tags. Tags are small elements of code
        on your website which, among other things, serve to measure traffic and
        visitor behavior, to identify the impacts of online advertisement and
        social channels, use remarketing and targeting and to test and optimize
        your website.
      </p>
      <p class="mb-0">
        This means: No additional cookies are used. Google Tag Manager triggers
        other tags, which may collect data. Google Tag Manager does not access
        this data. If deactivation has been made at the domain or cookie level –
        in particular, if you have opted for the Google Analytics opt-out
        solution described above or have made the corresponding settings in your
        browser – it will remain in effect for all tracking tags provided that
        these are implemented with the Google Tag Manager.
      </p>
      <p class="mb-0">For more information see Google’s privacy policy:</p>
      <b-link href="https://policies.google.com/terms?gl=DE&hl=en"
        >https://policies.google.com/terms?gl=DE&hl=en</b-link
      >
      <p>
        Privacy Policy for Advertising:
        <b-link href="https://www.google.de/intl/de/policies/technologies/ads"
          >https://www.google.de/intl/de/policies/technologies/ads</b-link
        >
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">7. Services with statistics cookies</h5>
      <p class="mb-0 font-weight-bold">Google Analytics with Google Signals</p>
      <p class="mb-0 font-weight-bold">a) Type and scope of data processing</p>
      <p class="mb-0">
        On our website we use the tracking tool Google Analytics of Google
        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, Tel:
        +353 1 543 1000, Fax: +353 1 686 5660 („Google“).
      </p>
      <p class="mb-0">
        We have concluded a so-called order processing agreement insofar as
        Google acts as a processor for us. The data sharing settings to Google
        has been deactivated, so that consequently there is no joint
        controllership with Google.
      </p>
      <p class="mb-0">
        Google Analytics uses cookies, which are text files placed on your
        computer, to help the website analyze how users use the site.
      </p>
      <p class="mb-0">
        The information generated by cookies about your use of this website is
        usually transferred to a Google server in the USA and stored there. On
        behalf of the operator of this website, Google will use this information
        for the purpose of systematically evaluating your use of the website,
        compiling reports on website activity and providing other services
        relating to website activity and internet usage to the website operator.
      </p>
      <p class="mb-0">
        Google Analytics uses AI (artificial intelligence) and machine learning
        through the application of algorithms to evaluate user behaviour. The
        algorithms automatically measure the usage behaviour of individual
        website users based on event data (so-called events).
      </p>
      <p class="mb-0">
        The algorithm that analyses these events can also identify specific user
        behaviour on other devices, making it possible to analyse the behaviour
        of website users across devices. If you carry out various separate
        sessions on different devices, Google Analytics can automatically
        summarise the sessions into a uniform cross-device user experience based
        on user ID, Google ID or device ID and create database models, including
        cross-device conversions. We use “Google Signals”, a technical extension
        of Google Analytics. With Google Signals, we can generate cross-device
        reports by Google (so-called “cross-device tracking”). Whenever you
        visit our website, Google Analytics collects, among other things, your
        location, search history and YouTube history, as well as demographic
        data (visitor data).
      </p>
      <p class="mb-0">
        If you have activated “personalized ads” in the settings in your Google
        account and linked your internet devices to your Google account, this
        data can be used for personalized advertising with the help of Google
        Signals.
      </p>
      <p class="mb-0">
        If you do not wish to use “Google Signals”, you can deactivate the
        “personalized advertising” option in your Google account settings. To do
        this, follow the instructions on this page:
      </p>
      <b-link href="https://support.google.com/ads/answer/2662922?hl=de"
        >https://support.google.com/ads/answer/2662922?hl=de</b-link
      >
      <p class="mb-0">
        You can find more information about Google Signals at the following link
      </p>
      <b-link href="https://support.google.com/analytics/answer/7532985?hl=de"
        >https://support.google.com/analytics/answer/7532985?hl=de</b-link
      >
      <p>
        If individual pages of our website are called up, the following data is
        stored:
      </p>
      <p class="mb-0" style="font-size: 12px">
        • three bytes of the IP-address of the calling system of the user
        (anonymized IP-address)
      </p>
      <p class="mb-0" style="font-size: 12px">• accessed website</p>
      <p class="mb-0" style="font-size: 12px">
        • website from which the user accessed the page of our website
        (referrer)
      </p>
      <p class="mb-0" style="font-size: 12px">
        • sub-pages that are called from the caller page
      </p>
      <p class="mb-0" style="font-size: 12px">• time spent on the website</p>
      <p class="mb-0" style="font-size: 12px">
        • frequency of a call of the website
      </p>
      <p class="mb-0" style="font-size: 12px">• scroll behavior and clicks</p>
      <p class="mb-0" style="font-size: 12px">
        • achievement of “website objectives” (e.g. newsletter registrations)
      </p>
      <p class="mb-0" style="font-size: 12px">• approximate location</p>
      <p style="font-size: 12px">
        • information about the used browser, internet provider and device
        information
      </p>
      <p>
        Google notifies that the IP addresses in Google Analytics must no longer
        be anonymised, as they are neither logged nor stored. Nevertheless, the
        IP address in Google Analytics is automatically shortened by the service
        (IP anonymisation). This means that the IP addresses are shortened by
        the last octet (e.g. 192.168.79.***; so-called IP masking). This means
        is no longer possible to assign the shortened IP address to the user’s
        computer or end device.
      </p>
      <p class="font-weight-bold">GDPR:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. The USA is a so-called third
        country, because it is located outside the EU. However, the USA has an
        adequacy decision from the European Commission (EU-U.S. Data Privacy
        Framework (DPF). The decision concludes that the United States ensures
        an adequate level of protection – comparable to that of the European
        Union – for personal data transferred from the EU to US companies under
        the new framework. Google LLC has certified itself according to the DPF:
      </p>
      <b-link
        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
        >https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active
      </b-link>
      <p class="my-3 font-weight-bold">FADP:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. We would like to point out that
        the Court of Justice of the European Union (CJEU) has doubts about the
        adequacy of the level of data protection in the USA. In particular,
        there is a risk that personal data may be processed by government
        authorities for control and monitoring purposes, possibly also without
        any legal remedy.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        The service of Google Analytics is used to analyse the usage behavior of
        our online presence.
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        The legal basis for the use of Google Analytics is your consent pursuant
        to Article 6(1)(a) GDPR.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        The stored data will be deleted as soon as the cookie expires, or you
        withdraw your consent. Google Analytics stores cookies in your web
        browser for a period of 14 months since your last visit. These cookies
        contain a randomly generated user ID that allows you to be recognized
        during future visits to the website. The recorded data is stored
        together with the randomly generated user ID, which enables the
        evaluation of pseudonymous user profiles. This user-related data is
        automatically deleted after 14 months. Other data remains stored in
        aggregated form for an unlimited period.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of withdrawal</p>
      <p class="mb-0">
        The stored data will be deleted as soon as you withdraw your consent by
        deselecting the selected cookie category “Statistics” under “Cookie
        Preferences”. In addition, you can prevent file processing by the cookie
        by downloading and installing a add-on under a browser to disable Google
        Analytics or by using this link to install an opt-out cookie on your
        device.
      </p>
      <p class="mb-0 font-weight-bold">f) Further information</p>
      <p class="mb-0">Learn more about the terms of use of Google Analytics:</p>
      <b-link
        href="https://marketingplatform.google.com/about/analytics/terms/gb/"
        >https://marketingplatform.google.com/about/analytics/terms/gb/
      </b-link>
      <p class="mb-0">
        Further information on Google Analytics data protection:
      </p>
      <b-link href="https://support.google.com/analytics/answer/6004245?hl=en"
        >https://support.google.com/analytics/answer/6004245?hl=en
      </b-link>
      <p class="mb-0">
        Further information on Google’s privacy policy can be found here:
        <b-link href="https://policies.google.com/terms?gl=DE&hl=en"
          >https://policies.google.com/terms?gl=DE&hl=en</b-link
        >
      </p>
      <p class="mb-0 font-weight-bold">g) Recipient</p>
      <p>
        As part of data processing, data is transferred to Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p class="mb-0 font-weight-bold">Matomo</p>
      <p class="mb-0 font-weight-bold">a) Type and scope of data processing</p>
      <p class="mb-0">
        Matomo is an open source software tool for web analysis. Among other
        things, a web analyst tool collects data about the last website accessed
        prior to loading the current website (so-called referrer), which
        sub-sites of the website you accessed or how often and for how long you
        viewed a sub-page.
      </p>
      <p class="mb-0">
        Matomo sets a cookie to analyse the use of our website. With each access
        of an individual page of the website, the internet browser is
        automatically prompted by the Matomo component to transmit data to our
        servers for the purpose of online analysis. As part of this technical
        process, we gain knowledge of your personal data, such as your IP
        address, which is used, among other things, to track the origin of
        visitors and clicks. Personal information such as the access time, the
        location from which an access originated and the frequency of visits to
        our website are stored by means of cookies. Each time you visit our
        website, this personal data, including the IP address of the Internet
        connection you use, is transferred to our server. This personal data is
        stored by us and will not be disclosed to third parties. We use Matomo
        with the with enabled IP anonymization “automatically anonymize visitor
        IPs“. This anonymization function shortens your IP address by two bytes
        to make it impossible to assign it to you or the internet connection you
        are using.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        The purpose of the Matomo component is the analysis of flows of visitors
        to our website. Amongst other things, we use the obtained data and
        information to evaluate the use of this website.
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        The legal basis for the use of Matomo is your consent pursuant to
        Article 6(1)(a) GDPR.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        The stored data will be deleted as soon as the cookie expires, or you
        withdraw your consent.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of withdrawal</p>
      <p class="mb-0">
        The stored data will be deleted as soon as you withdraw your consent by
        deselecting the selected cookie category “Statistics” under “Cookie
        Preferences”.
      </p>
      <p class="mb-0 font-weight-bold">f) Further information</p>
      <p class="mb-0">
        Further information and the valid data protection regulations of Matomo
        can be found here:
        <b-link href="https://matomo.org/privacy/"
          >https://matomo.org/privacy/</b-link
        >
      </p>
      <p class="mb-0 font-weight-bold">g) Recipient</p>
      <p>
        As part of data processing, data is transferred to InnoCraft Ltd, 150
        Willis St, 6011 Wellington, New Zealand, NZBN 6106769.
      </p>
      <p class="mb-0 font-weight-bold">Microsoft Clarity</p>
      <p class="mb-0 font-weight-bold">a) Type and extent of data processing</p>
      <p>
        We use the Microsoft Clarity for statistical analysis of the use of our
        website. Microsoft Clarity is provided by Microsoft Corporation, One
        Microsoft Way, Redmond, WA 98052 USA (“Microsoft”). In particular, we
        process usage data (e.g. internet presentations visited, interest in
        content, access times), meta/communication data (e.g. device
        information, IP addresses), location data (information on the
        geographical position of a device or a person), movement data (mouse
        movements, scrolling movements) in pseudonymized form. We have made the
        appropriate settings so that even the data collection to and by
        Microsoft alone is pseudonymized, especially in the form of IP masking
        (pseudonymization of the IP address).
      </p>
      <p class="font-weight-bold">GDPR:</p>
      <p class="mb-0">
        Microsoft Ireland transmits data to Microsoft Corporation, One Microsoft
        Way Redmond, WA 98052-6399 USA. The USA is a so-called third country,
        because it is located outside the EU. However, the USA has an adequacy
        decision from the European Commission (EU-U.S. Data Privacy Framework
        (DPF). The decision concludes that the United States ensures an adequate
        level of protection – comparable to that of the European Union – for
        personal data transferred from the EU to US companies under the new
        framework. Microsoft Corporation has certified itself according to the
        DPF:
      </p>
      <b-link
        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000KzNaAAK&status=Active"
        >https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000KzNaAAK&status=Active</b-link
      >
      <p class="font-weight-bold mt-3">FADP:</p>
      <p class="mb-0">
        In the context of this service, data transmission to the USA takes place
        or cannot be ruled out. We would like to point out that the Court of
        Justice of the European Union (CJEU) has doubts about the adequacy of
        the level of data protection in the USA. In particular, there is a risk
        that personal data may be processed by government authorities for
        control and monitoring purposes, possibly also without any legal remedy.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        The processing is carried out for the purpose of statistical analysis of
        user behavior and for optimization and marketing purposes.
      </p>
      <p class="font-weight-bold mb-0">c) Legal basis</p>
      <p class="mb-0">
        The processing of your data is based on your consent pursuant to Article
        6(1)(a) GDPR. You can withdraw this consent at any time for the future.
      </p>
      <p class="font-weight-bold mb-0">d) Storage period</p>
      <p class="mb-0">
        The stored data will be deleted as soon as they are no longer needed for
        our purposes.
      </p>
      <p class="font-weight-bold mb-0">e) Right of withdrawal</p>
      <p class="mb-0">
        The stored data will be deleted as soon as you withdraw your consent by
        deselecting the selected cookie category “Statistics” under “Cookie
        Preferences”.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">8. Services with marketing cookies</h5>
      <p class="font-weight-bold mb-0">LinkedIn Insight Tag</p>
      <p class="font-weight-bold mb-0">a) Type and extent of data processing</p>
      <p>
        We use the conversion tool “LinkedIn Insight Tag” of LinkedIn Ireland
        Unlimited Company, which is integrated on this website. With the help of
        a cookie, the following data is processed:
      </p>
      <p class="mb-0" style="font-size: 12px">• URL</p>
      <p class="mb-0" style="font-size: 12px">• referrer URL</p>
      <p class="mb-0" style="font-size: 12px">• IP address</p>
      <p class="mb-0" style="font-size: 12px">
        • device and browser properties (user agent)
      </p>
      <p class="mb-0" style="font-size: 12px">
        • page activity (e.g. page views)
      </p>
      <p style="font-size: 12px">• timestampURL</p>
      <p>
        The embedded LinkedIn Insight tag establishes a connection to the
        LinkedIn server if you visit this website and are logged into your
        LinkedIn account at the same time. The data collected by the LinkedIn
        Insight tag is encrypted. The information generated by the tag about
        your use of this website may be transmitted to LinkedIn servers outside
        the EU and stored there.
      </p>
      <p class="font-weight-bold">GDPR / FADP:</p>
      <p class="mb-0">
        We would like to point out that the Court of Justice of the European
        Union (CJEU) has doubts about the adequacy of the level of data
        protection in the USA. In particular, there is a risk that personal data
        may be processed by government authorities for control and monitoring
        purposes, possibly also without any legal remedy.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        LinkedIn does not share any personally identifiable information with us,
        but only provides reports and notifications (in which you are not
        identified) about website audience and ad performance. LinkedIn also
        provides retargeting for website visitors, so we can use this data to
        display targeted ads outside of our website without identifying you as a
        member
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        The legal basis for the use of Google Analytics is your consent pursuant
        to Article 6(1)(a) GDPR.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        Members’ direct identifiers are removed within seven days to
        pseudonymize the data. This remaining pseudonymized data is then deleted
        within 180 days.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of withdrawal</p>
      <p class="mb-0">
        The stored data will be deleted as soon as you withdraw your consent by
        deselecting the selected cookie category “Statistics” under “Cookie
        Preferences”. LinkedIn members can opt out of the use of their personal
        data for promotional purposes in their account settings under the
        section “interactions with companies”.
      </p>
      <p class="mb-0">
        Alternatively, you can give a revocation under this link:
      </p>
      <b-link
        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
        >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</b-link
      >
      <p class="mb-0 font-weight-bold">f) Further information</p>
      <p class="mb-0">
        For more information, please see the privacy policy of LinkedIn Ireland
        Unlimited Company:
      </p>
      <b-link href="https://www.linkedin.com/legal/privacy-policy"
        >https://www.linkedin.com/legal/privacy-policy,</b-link
      >
      <p class="mb-0">in the cookie policy:</p>
      <p class="mb-0">
        <b-link
          href="https://de.linkedin.com/legal/cookie-policy and in the faqs to the LinkedIn Insight-Tag: https://www.linkedin.com/help/lms/answer/65521"
          >https://de.linkedin.com/legal/cookie-policy</b-link
        >
        and in the faqs to the LinkedIn Insight-Tag:
        <b-link href="https://www.linkedin.com/help/lms/answer/65521"
          >https://www.linkedin.com/help/lms/answer/65521</b-link
        >
      </p>

      <p class="mb-0 font-weight-bold">g) Recipient</p>
      <p class="mb-0">
        As part of data processing, data is transferred to LinkedIn Ireland
        Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Ireland.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">9. Services with external media</h5>
      <p class="font-weight-bold mb-0">Integration of YouTube videos</p>
      <p class="font-weight-bold mb-0">a) Type and scope of data processing</p>
      <p class>
        In certain areas of our website, we have integrated YouTube videos in
        order to present you with interesting video content directly on our
        website. The content is provided by Google Inc, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA (“Google”). Google Ireland Limited,
        Gordon House, Barrow Street, Dublin 4, Ireland, Tel: +353 1 543 1000,
        Fax: +353 1 686 5660 (“Google”) is responsible for providing the service
        in the European area. We use YouTube in extended data protection mode,
        which blocks the setting of YouTube cookies until you consent in the use
        of external media in our consent management platform or an active click
        on the YouTube Play button occurs. By clicking on the YouTube Play
        button, you consent to YouTube setting cookies on the device you are
        using, transmitting your IP address, browser information or other
        personal information to Google, which may also be used to analyze user
        behavior for market research and marketing purposes.
      </p>
      <p class="mb-0 font-weight-bold mb-3">GDPR:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. The USA is a so-called third
        country, because it is located outside the EU. However, the USA has an
        adequacy decision from the European Commission (EU-U.S. Data Privacy
        Framework (DPF). The decision concludes that the United States ensures
        an adequate level of protection – comparable to that of the European
        Union – for personal data transferred from the EU to US companies under
        the new framework. Google LLC has certified itself according to the DPF:
      </p>
      <b-link
        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active"
        >https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active</b-link
      >
      <p class="font-weight-bold my-3">FADP:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. We would like to point out that
        the European Court of Justice (ECJ) has doubts about the adequacy of the
        level of data protection in the USA. In particular, there is a risk that
        personal data may be processed by government authorities for control and
        monitoring purposes, possibly also without any legal remedy. For this
        reason, the play function of the video is initially blocked when you
        call up our website. The transmission of data to third parties is thus
        blocked until an active click on the YouTube Play button occurs. This
        means that a direct connection to Google’s servers is only established
        when you independently activate the playback of the corresponding video.
        This procedure is the so-called “two-click solution”, in which data is
        not transmitted to third parties simply by calling up the website. As a
        website visitor you must first click on a preview button – in our case
        the YouTube Play button on the video – and only then a corresponding
        data flow will take place.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        Displaying video content directly on the website as well as enabling a
        comfortable use of the map function by the website user.
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        The legal basis is your consent pursuant to Article 6(a) GDPR.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        The stored data will be deleted as soon as the cookie expires, or you
        withdraw your consent.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of withdrawal</p>
      <p class="mb-0">
        You can withdraw your consent by deselecting the selected cookie
        category “external media” under “Cookie Preferences”. f) Further
        information Further information on data processing by Google can be
        found in Google’s privacy policy at
        https://www.google.com/intl/en/policies/privacy
      </p>
      <p class="mb-0 font-weight-bold">g) Recipient</p>
      <p class="mb-0">
        As part of data processing, data is transferred to Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">10. Google Fonts</h5>
      <p class="font-weight-bold mb-0">a) Type and scope of data processing</p>
      <p>
        We use external fonts from Google Fonts on this website. Google Fonts is
        a service of Google Ireland Limited, Gordon House, Barrow Street, Dublin
        4, Ireland, Tel: +353 1 543 1000, Fax: +353 1 686 5660 („Google”). The
        integration of these web fonts is carried out through a server call,
        usually one of Google’s servers in the United States. Hereby, the server
        will receive information on which websites you visited. Also, Google
        stores the IP address of the visitor’s terminal device’s browser.
      </p>
      <p class="mb-0 font-weight-bold mb-3">GDPR:</p>
      <p>
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. The USA is a so-called third
        country, because it is located outside the EU. However, the USA has an
        adequacy decision from the European Commission (EU-U.S. Data Privacy
        Framework (DPF). The decision concludes that the United States ensures
        an adequate level of protection – comparable to that of the European
        Union – for personal data transferred from the EU to US companies under
        the new framework. Google LLC has certified itself according to the DPF:
        https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active
      </p>
      <p class="mb-0 font-weight-bold mb-3">FADP:</p>
      <p class="mb-0">
        Google Ireland Limited transmits data to Google LLC, 1600 Amphitheatre
        Parkway Mountain View, CA 94043, USA. We would like to point out that
        the European Court of Justice (ECJ) has doubts about the adequacy of the
        level of data protection in the USA. In particular, there is a risk that
        personal data may be processed by government authorities for control and
        monitoring purposes, possibly also without any legal remedy.
      </p>
      <p class="mb-0 font-weight-bold">b) Purpose of data processing</p>
      <p class="mb-0">
        The purpose of using Google Fonts is the uniform presentation of fonts.
      </p>
      <p class="mb-0 font-weight-bold">c) Legal basis</p>
      <p class="mb-0">
        Legal basis for the use of Google Fonts is Article 6(1)(f) GDPR. We have
        a legitimate interest in the interest of a uniform and consistent
        presentation of our online offers.
      </p>
      <p class="mb-0 font-weight-bold">d) Storage period</p>
      <p class="mb-0">
        The stored data will be deleted as soon as they are no longer needed for
        our purposes.
      </p>
      <p class="mb-0 font-weight-bold">e) Right of objection</p>
      <p class="mb-0">
        You have the right to object to this processing. However, we have
        legitimate compelling reasons for processing the data, because without
        processing this data we cannot properly display the fonts on the
        website.
      </p>
      <p class="mb-0 font-weight-bold">f) Further information</p>
      <p class="mb-0">
        Further information on data processing by Google can be found in
        Google’s privacy policy at
        https://www.google.com/intl/en/policies/privacy
      </p>
      <p class="mb-0 font-weight-bold">g) Recipient</p>
      <p class="mb-0">
        As part of data processing, data is transferred to Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">
        11. Data collection for the implementation of pre-contractual measures
        and for contract fulfilment
      </h5>
      <p class="font-weight-bold mb-0">a) Type and scope of data processing</p>
      <p class="mb-0">
        In the pre-contractual area and at the conclusion of the contract we
        collect personal data about you (e.g., first and last name, address,
        e-mail address, telephone number, bank details).
      </p>
      <p class="font-weight-bold mb-0">b) Purpose of data processing</p>
      <p class="mb-0">
        We collect and process this data exclusively for the purpose of contract
        execution and/or for the fulfilment of pre-contractual obligations
      </p>
      <p class="font-weight-bold mb-0">c) Legal basis</p>
      <p class="mb-0">
        The legal basis for this is Article 6(1)(b) GDPR. The processing of the
        data serves the fulfilment of a contract or the implementation of
        pre-contractual measures
      </p>
      <p class="font-weight-bold mb-0">d) Storage period</p>
      <p class="mb-0">
        The data will be deleted as soon as they are no longer necessary for the
        purpose of their processing. In addition, statutory retention
        obligations may exist, such as commercial or tax retention obligations
        in accordance with the German Commercial Code (HGB) or the German Fiscal
        Code (AO). If such storage obligations exist, we will block or delete
        your data at the end of these storage obligations.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">12. Data transmission</h5>
      <p class="mb-0">
        We only pass on your personal data to third parties if a) you have given
        your explicit consent to do so in accordance with Article 6(1)(a) GDPR.
        b) this is legally permissible and, in accordance with Article 6(1)(b)
        GDPR, is necessary for the fulfilment of a contractual relationship with
        you or the implementation of pre-contractual measures. c) there is a
        legal obligation under Article 6(1)(c) GDPR for the transfer.
      </p>

      <p class="mb-0">
        We are legally obliged to transfer data to state authorities, e.g. tax
        authorities, social security carriers, health insurances, supervisory
        authorities and law enforcement agencies. d) the disclosure in
        accordance with Article 6(1)(f) GDPR is necessary to safeguard
        legitimate corporate interests and to assert, exercise or defend legal
        claims, and there is no reason to assume that you have an overriding
        interest worthy of protection in the non-disclosure of your data.
      </p>
      <p class="mb-0">
        We use external service providers (so-called processors) to process
        personal data in accordance with Article 28(3) GDPR. These processors
        have been carefully selected by us and are obliged by a data processing
        agreement to handle personal data in accordance with data protection
        regulations.
      </p>
      <p>We use such external service providers in the following areas:</p>
      <p class="mb-0" style="font-size: 12px">• IT</p>
      <p class="mb-0" style="font-size: 12px">• logistics</p>
      <p class="mb-0" style="font-size: 12px">• telecommunications</p>
      <p class="mb-0" style="font-size: 12px">• distribution</p>
      <p style="font-size: 12px">• marketing</p>
      <p class="font-weight-bold">GDPR:</p>
      <p>
        When transferring personal data to so-called third countries, i.e.
        outside the EU or EEA, we ensure that your personal data is treated with
        the same care as within the EU or EEA. We only transfer personal data to
        third countries where the EU Commission has confirmed an adequate level
        of data protection or where we have ensured the careful handling of
        personal data by contractual agreements or other suitable guarantees.
      </p>
      <p class="font-weight-bold">FADP:</p>
      <p>
        When transferring personal data abroad, we ensure that personal data is
        treated with the same care. We only transfer personal data abroad if the
        legislation of the State concerned or the international body guarantees
        an adequate level of protection. In the absence of a decision by the
        Federal Council we disclose personal data abroad only if an adequate
        level of data protection is guaranteed by a treaty under international
        law, data protection clauses in an agreement between the controller or
        the processor and its contractual partner, notice of which has been
        given to the FDPIC beforehand; specific guarantees drawn up by the
        competent federal body, notice of which has been given to the FDPIC
        beforehand, standard data protection clauses that the FDPIC has
        approved, issued or recognised beforehand; or binding corporate rules
        that have been approved in advance by the FDPIC or by the authority
        responsible for data protection in a State that guarantees an adequate
        level of protection.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">13. Contact form</h5>
      <p class="font-weight-bold mb-0">a) Type and scope of data processing</p>
      <p class="mb-0">
        On our website we allow you to send us messages via our contact form.
        When you use our contact form, you can enter your name, your email
        address and the content of your message. The entry of this information
        is voluntary. Only if you provide us with your name, we can contact you
        under your name. We can only contact you using the contact data that you
        provide to us. If you do not provide us with contact data, we will not
        be able to respond to your request.
      </p>
      <p class="font-weight-bold mb-0">b) Purpose of data processing</p>
      <p class="mb-0">
        If you send us a message via our contact form, we store and use your
        information to the extent that we need it to process your message, e.g.
        to respond to your inquiry or to fulfil your request for information.
      </p>
      <p class="font-weight-bold mb-0">c) Legal basis</p>
      <p class="mb-0">
        If your inquiry serves the preparation of the conclusion of a contract
        or the conclusion of a contract with us, the legal basis for the
        processing of your personal data is Article 6(1)(b) GDPR. Otherwise,
        i.e. when contacting us via our contact form in cases other than those
        listed above, Article 6(1)(f) GDPR is the legal basis for the processing
        of your personal data. Our legitimate interest for processing your
        personal data follows from the processing of your message, e.g.
        answering your inquiry or fulfilling your request for information.
      </p>
      <p class="font-weight-bold mb-0">d) Storage period</p>
      <p class="mb-0">
        Your data will be deleted after final processing of your message, unless
        you have given us consent on the basis of which we are entitled to
        further storage and use. Mandatory legal provisions – in particular
        retention periods according to the German Commercial Code (HGB) or the
        German Fiscal Code (AO) – remain unaffected by this.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">14. Contact options by e-mail</h5>
      <p class="font-weight-bold mb-0">a) Type and scope of data processing</p>
      <p class="mb-0">
        You can contact us by e-mail. Our data collection is limited to the
        e-mail address of the e-mail account used by you to contact us as well
        as to the personal data provided by you in the course of contacting us.
        If you send us an e-mail without encryption, the e-mail is not protected
        against unauthorized access or modification by third parties during
        transmission.
      </p>
      <p class="font-weight-bold mb-0">b) Purpose of data processing</p>
      <p class="mb-0">
        The purpose of data processing is to be able to answer your request
        appropriately.
      </p>
      <p class="font-weight-bold mb-0">c) Legal basis</p>
      <p class="mb-0">
        The legal basis for this is Article 6(1)(f) GDPR. There is a legitimate
        interest in the processing of the above-mentioned personal data in order
        to be able to process your request appropriately, e.g. to answer your
        inquiry or to fulfil your request for information.
      </p>
      <p class="font-weight-bold mb-0">d) Storage period</p>
      <p class="mb-0">
        The duration of the storage of the above-mentioned data depends on the
        background of your contact. Your personal data will be deleted on a
        regular basis if the intended purpose of the communication ceases to
        apply and storage is no longer necessary. This may result, for example,
        from processing your request.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">
        15. Data security and security measures
      </h5>
      <p class="mb-0">
        We are committed to protecting your privacy and treating your personal
        data confidentially. For this purpose, we take extensive technical and
        organisational security precautions, which are regularly checked and
        adapted to technological progress. These include the use of recognised
        encryption procedures (SSL or TLS). Unencrypted data, e.g. when sent by
        unencrypted e-mail, may be read by third parties. We have no influence
        on this. It is the responsibility of the respective user to protect the
        data provided by him/her against misuse by means of encryption or in any
        other way.
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">16. Your rights (as a data subject)</h5>
      <p class="mb-0">
        Here you will find your rights regarding your personal data. Details of
        this are set out in Chapter 4 and 5 of the FADP or Articles 7, 15-22 and
        77 of the GDPR, as applicable. You can contact the controller (Section
        2) in this regard.
      </p>
      <p class="font-weight-bold mb-0">
        a) Under the FADP you have the following rights:
      </p>
      <p class="font-weight-bold mb-0">
        aa) Right to information according to Article 25 FADP
      </p>
      <p class="mb-0">
        You have the right to request confirmation as to whether we process
        personal data relating to you. If this is the case, you have the right
        to be informed about your personal data and to receive further
        information, identity and the contact details of the controller; the
        processed personal data, the purpose of processing; the retention period
        for the personal data, the available information about the source of the
        personal data, recipients or the categories of recipients to which
        personal data is disclosed, as well as the information specified in
        Article 19 paragraph 4. We may refuse to provide information, or
        restrict or delay the provision of information according to Article 26,
        27 FADP.
      </p>
      <p class="font-weight-bold mb-0">
        bb) Right to data portability according to Article 28 FADP
      </p>
      <p class="mb-0">
        You have the right to request the controller to deliver the personal
        data that they have disclosed to it in a conventional electronic format.
        The controller may refuse, restrict or delay the delivery or transfer of
        personal data for the reasons set out in Article 26 paragraphs 1 and 2
        according to Article 29. The controller must give reasons why it has
        decided to refuse, restrict or delay the delivery or transfer.
      </p>
      <p class="font-weight-bold mb-0">
        cc) Right to correction according to Article 32 (1) FADP
      </p>
      <p class="mb-0">
        You have the right to request that incorrect personal data be corrected
        unless: a statutory provision prohibits the correction or the personal
        data are processed for archiving purposes that are in the public
        interest.
      </p>
      <p class="font-weight-bold mb-0">
        b) Under the GDPR you have the following rights:
      </p>
      <p class="font-weight-bold mb-0">
        aa) Right to withdraw your data protection consent in accordance with
        Article 7(3) GDPR
      </p>
      <p class="mb-0">
        You can withdraw your consent to the processing of your personal data at
        any time with effect for the future. The withdrawal of consent shall not
        affect the lawfulness of processing based on consent before its
        withdrawal. bb) Right of access according to Article 15 GDPR You have
        the right to request confirmation as to whether we process personal data
        concerning you. If this is the case, you have the right to be informed
        about your personal data and to receive further information, e.g. the
        purposes of processing, the categories of personal data processed, the
        recipients and the planned duration of storage or the criteria for
        determining the duration.
      </p>
      <p class="font-weight-bold mb-0">
        cc) Right to rectification and completion under Article 16 GDPR
      </p>
      <p class="mb-0">
        You have the right to demand the correction of incorrect data without
        delay. Taking into account the purposes of the processing, you have the
        right to request the completion of incomplete data.
      </p>
      <p class="font-weight-bold mb-0">
        dd) Right to erasure („right to be forgotten“) in accordance with
        Article 17 GDPR
      </p>
      <p class="mb-0">
        You have the right of erasure, as far as the processing is not
        necessary. This is the case, for example, if your data is no longer
        necessary for the original purposes, if you have withdrawn your
        declaration of consent under data protection law or if the data was
        processed unlawfully.
      </p>
      <p class="font-weight-bold mb-0">
        ee) Right to restriction of processing in accordance with Article 18
        GDPR
      </p>
      <p class="mb-0">
        You have the right to limit the processing, for example if you believe
        that personal data is incorrect. ff) Right to data portability according
        to Article 20 GDPR You have the right to receive personal data
        concerning you in a structured, common and machine-readable format.
      </p>
      <p class="font-weight-bold mb-0">
        gg) Right to object according to Article 21 GDPR
      </p>
      <p class="mb-0">
        You have the right to object at any time for reasons arising from your
        particular situation to the processing of certain personal data
        concerning you. In the case of direct marketing, you, as the data
        subject, have the right to object at any time to the processing of
        personal data concerning you for the purposes of such marketing,
        including profiling, insofar as it relates to such direct marketing.
      </p>
      <p class="font-weight-bold mb-0">
        hh) Automated individual decision-making, including profiling in
        accordance with Article 22 GDPR
      </p>
      <p class="mb-0">
        You have the right not to be subject to a decision based solely on
        automated processing, including profiling, except in the exceptional
        circumstances referred to in Article 22 GDPR. You will not be subject to
        a decision based solely on automated processing of your data, including
        profiling (Article 13 (2) (f) GDPR, Articles 22 (1) to (4) GDPR, Article
        4 (4) GDPR, Articles 22 (1) to (4) GDPR), which would have legal effect
        on you or would have a similarly significant adverse effect on you.
      </p>
      <p class="font-weight-bold mb-0">
        ii) Right to lodge a complaint with a data protection supervisory
        authority according to Article 77 GDPR
      </p>
      <p class="mb-0">
        You can also lodge a complaint with a data protection supervisory
        authority at any time, for example if you believe that data processing
        is not in compliance with data protection regulations.
      </p>
      <p class="font-weight-bold mb-0">Competent supervisory authority:</p>
      <p class="mb-0">Bayerisches Landesamt für Datenschutzaufsicht</p>
      <p class="mb-0">Promenade 18</p>
      <p>91522 Ansbach Germany</p>
      <p class="mb-0">Postal address:</p>
      <p class="mb-0">Postfach 1349,</p>
      <p class="mb-0">91504 Ansbach, Germany</p>
      <p class="mb-0">Tel.: 0981/180093-0</p>
      <p class="mb-0">Telefax: 0981/180093-800</p>
      <p class="mb-0">
        Email:
        <b-link href="poststelle@lda.bayern.de"
          >poststelle@lda.bayern.de</b-link
        >
      </p>
      <p class="mb-0">
        Homepage:
        <b-link href="https://www.lda.bayern.de"
          >https://www.lda.bayern.de</b-link
        >
      </p>
    </div>
    <div class="mb-4">
      <h5 class="font-weight-bold mb-3">17. Changes to this privacy policy</h5>
      <p class>
        Our privacy policy serves the fulfilment of legal information duties. We
        update our data protection declaration as far as this becomes necessary.
      </p>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "DataPolicy",
  data() {
    return {};
  },
};
</script>
