<template>
  <footer class="footer">
    <div
      class="d-flex justify-content-center align-items-center flex-wrap mx-4"
    >
      <p class="footer-copyright">©2024 QMware GmbH</p>
      <p class="footer-copyright mx-2">•</p>
      <b-link class="footer-links" to="#" @click="openModalImprint"
        >Imprint</b-link
      >
      <p class="footer-copyright mx-2">•</p>
      <b-link class="footer-links" to="#" @click="openModalDataProtection"
        >Data Protection Policy</b-link
      >
      <Imprint />
      <DataPolicy />
    </div>
  </footer>
</template>

<script>
import Imprint from "@/components/Modals/Imprint.vue";
import DataPolicy from "@/components/Modals/DataPolicy.vue";
export default {
  name: "Footer",
  components: {
    Imprint,
    DataPolicy,
  },
  data() {
    return {};
  },
  methods: {
    openModalImprint() {
      this.$bvModal.show("imprintModal");
    },
    openModalDataProtection() {
      this.$bvModal.show("dataProtectionModal");
    },
  },
};
</script>
